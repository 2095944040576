import { ILabel } from "@faro-lotv/ielement-types";
import { Grid, LinearProgress, Stack, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";
import { Alert } from "../../alert/alert";
import { FaroButton } from "../../button/faro-button";
import { FaroChipTag } from "../../chip";
import { cyan, neutral } from "../../colors";
import { DatePicker, DatePickerProps } from "../../date-picker/date-picker";
import { Dropdown, DropdownSelect } from "../../dropdown/dropdown";
import { DropdownProps, Option } from "../../dropdown/dropdown-types";
import {
  FilterMenuContent,
  FilterMenuOptionType,
} from "../../filter-menu/filter-menu-content";
import { PlusIcon } from "../../icons";
import { FaroText } from "../../text/faro-text/faro-text";
import { NoTranslate } from "../../translation";
import {
  FileAttachmentsList,
  FileAttachmentsListProps,
} from "./file-attachments-list";

/** Generic interface for a tag */
type Tag = Pick<ILabel, "id" | "name">;

// TODO: Check if the style of the dropdown is correct after https://faro01.atlassian.net/browse/SWEB-3745
// Common style applied to all dropdown fields
export const dropDownStyle: SxProps<Theme> = {
  ".MuiSelect-select": {
    // Make the dropdown 38px high, which is the same height as the DatePicker
    py: "7px !important",
  },
  ".MuiTypography-root": {
    fontSize: "0.875rem",
  },
};

export type AnnotationEditorMetaFieldsProps = FileAttachmentsListProps & {
  /** Current value of the assignee dropdown field. By default the annotation is unassigned */
  assignee?: string;

  /** Initial value of the due date field. To get the current value use onDueDateChange */
  initialDate?: Date;

  /** Current value of the status dropdown field. By default the status is unassigned */
  status?: string;

  /**
   * List of available option to select in the assignee dropdown field.
   * An option to unselect the assignee will be added automatically to the list
   */
  assigneeOptions: Option[];

  /**
   * List of available option to select in the status dropdown field
   * An option to unselect the status will be added automatically to the list
   */
  statusOptions: Option[];

  /** List of all tags available for this element*/
  tagsOptions: FilterMenuOptionType[];

  /** List of the tags selected by the user */
  tags: Tag[];

  /** Callback executed when clicking on the "Add new tag" button*/
  onAddNewTagClick(): void;

  /** Function called when the selected value of the assignee dropdown field changes */
  onAssigneeChange?: DropdownProps["onChange"];

  /** Function called when the selected due date value changes */
  onDueDateChange?: DatePickerProps["onChange"];

  /** Function called when the selected value of the status dropdown field changes */
  onStatusChange?: DropdownProps["onChange"];

  /** Function called when the user changes the selected tags */
  onTagsChange(tags: Tag[]): void;

  /** If true the attachments list will be shown */
  shouldShowAttachments: boolean;

  /** True if the controls should be enabled */
  isSaving: boolean;

  /** The progress in percetage for the upload of the attachments */
  progress?: number;
};

/**
 * @returns UI to allow to set and adjust the meta fields of an annotation (status, assignee, etc.)
 */
export function AnnotationEditorMetaFields({
  assignee,
  initialDate,
  status,
  assigneeOptions,
  statusOptions,
  tagsOptions,
  attachments,
  tags,
  addNewAttachment,
  onAssigneeChange,
  onDueDateChange,
  onStatusChange,
  onTagsChange,
  onAttachmentOpened,
  onAddNewTagClick,
  shouldShowAttachments,
  isSaving,
  progress,
}: AnnotationEditorMetaFieldsProps): JSX.Element {
  /** Assign dropdown options that also contain an option to unassign */
  const fullAssigneeOptions = useMemo(
    () => [
      { key: "unassigned", value: "", label: "-" },
      ...assigneeOptions.map((o) => ({
        ...o,
        label: <NoTranslate>{o.label}</NoTranslate>,
      })),
    ],
    [assigneeOptions],
  );

  /** Assign dropdown options that also contain an option to unassign */
  const fullStatusOptions = useMemo(
    () => [
      { key: "unassigned", value: "", label: "Unclassified" },
      ...statusOptions,
    ],
    [statusOptions],
  );

  const selectedTagsOptions = useMemo(
    () =>
      tags.map((tag) => ({
        key: tag.id,
        value: tag.name,
        label:
          tag.name === "Untagged" ? (
            tag.name
          ) : (
            <NoTranslate>{tag.name}</NoTranslate>
          ),
      })),
    [tags],
  );

  const untranslatedTagsOptions = useMemo(
    () =>
      tagsOptions.map((tag) => ({
        ...tag,
        label:
          tag.label === "Untagged" ? (
            tag.label
          ) : (
            <NoTranslate>{tag.label}</NoTranslate>
          ),
      })),
    [tagsOptions],
  );

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={6} py={0}>
          <Dropdown
            label="Assignee"
            disabled={isSaving}
            value={assignee}
            options={fullAssigneeOptions}
            dark
            fullWidth
            sx={dropDownStyle}
            onChange={onAssigneeChange}
            shouldCapitalize={false}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            label="Due date"
            disabled={isSaving}
            initialDate={initialDate}
            variant="Dark"
            formSx={{ width: "100%" }}
            onChange={onDueDateChange}
            isClearable
          />
        </Grid>
        <Grid item xs={6} py={0}>
          <Dropdown
            label="Status"
            disabled={isSaving}
            value={status}
            options={fullStatusOptions}
            dark
            fullWidth
            sx={dropDownStyle}
            onChange={onStatusChange}
            shouldCapitalize={false}
          />
        </Grid>
        <Grid item xs={6} py={0}>
          <DropdownSelect
            dark
            disabled={isSaving}
            label="Tags"
            sx={dropDownStyle}
            renderValue={() => {
              if (!tags.length) {
                return (
                  <FaroText
                    variant="bodyM"
                    sx={{
                      fontStyle: "italic",
                      mr: 1,
                      color: neutral[500],
                    }}
                  >
                    Select tags
                  </FaroText>
                );
              }

              return <FaroChipTag size="small" label={`${tags.length}`} dark />;
            }}
          >
            <FilterMenuContent
              dark
              selectedOptions={selectedTagsOptions}
              options={untranslatedTagsOptions}
              setSelectedOptions={(options) =>
                onTagsChange(
                  options.map((o) => ({
                    id: o.key,
                    name: o.value,
                  })),
                )
              }
            >
              <FaroButton
                variant="ghost"
                dark
                sx={{
                  color: cyan[400],
                  textDecoration: "underline",
                  justifyContent: "flex-start",
                }}
                icon={<PlusIcon />}
                onClick={onAddNewTagClick}
              >
                Add new tag
              </FaroButton>
            </FilterMenuContent>
          </DropdownSelect>
        </Grid>
      </Grid>
      {shouldShowAttachments && (
        <Stack gap={1}>
          <FaroText variant="labelM" sx={{ color: neutral[0] }}>
            File attachments
          </FaroText>
          <FileAttachmentsList
            attachments={attachments}
            addNewAttachment={addNewAttachment}
            onAttachmentOpened={onAttachmentOpened}
          />
        </Stack>
      )}
      {progress !== undefined && (
        <Alert variant="info" dark title={`Uploading attachments ${progress}%`}>
          <LinearProgress
            sx={{
              mt: 1,
              "& .MuiLinearProgress-bar": {
                background: cyan[500],
              },
            }}
            variant="determinate"
            value={progress}
          />
        </Alert>
      )}
    </>
  );
}
