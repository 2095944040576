import {
  ExternalMarkupIElement,
  GENERIC_DATASET_TYPE_HINTS,
  IElemLink,
  IElemLinkCommand,
  IElement,
  IElementAiMaterialDetection,
  IElementAiWall,
  IElementAttachment,
  IElementClippingBox,
  IElementDataSetVideoWalk,
  IElementDateTimeMarkupField,
  IElementDateTimeMarkupFieldTemplate,
  IElementDepthMap,
  IElementDropDownMarkupField,
  IElementDropDownMarkupFieldTemplate,
  IElementFloorLayout,
  IElementGenericAnnotation,
  IElementGenericDataset,
  IElementGenericImgSheet,
  IElementGenericModel3d,
  IElementGenericPointCloud,
  IElementGenericPointCloudStream,
  IElementGenericStream,
  IElementGenericVideoRecording,
  IElementGroup,
  IElementImageCommand,
  IElementImg2d,
  IElementImg360,
  IElementImgCube,
  IElementImgSheet,
  IElementImgSheetTiled,
  IElementMarkup,
  IElementMarkupCommand,
  IElementMarkupPolygon,
  IElementMarkupTemplate,
  IElementMeasurePolygon,
  IElementModel3D,
  IElementModel3dStream,
  IElementPdfCommand,
  IElementPointCloudCpe,
  IElementPointCloudE57,
  IElementPointCloudGeoSlam,
  IElementPointCloudLaz,
  IElementPointCloudStream,
  IElementPointCloudStreamWebShare,
  IElementProjectRoot,
  IElementRoomLayout,
  IElementSection,
  IElementTimeSeries,
  IElementTimeSeriesDataSession,
  IElementUrlLink,
  IElementUrlLinkCommand,
  IElementUserDirectoryMarkupField,
  IElementUserDirectoryMarkupFieldTemplate,
  IElementVideo360,
  IElementWithFileUri,
  IElementWithUri,
} from "../i-element";
import { IElementType, IElementTypeHint, WithHint } from "../i-element-base";
import { IPose } from "../properties";

/**
 * @param element to check
 * @param type to check
 * @param hint to check
 * @returns true if the element has the proper type and type-hint
 */
export function isIElementWithTypeAndHint<
  Element extends IElement,
  Type extends IElementType,
  Hint extends IElementTypeHint,
>(
  element: Element,
  type: Type,
  hint: Hint,
): element is Element & { type: Type; typeHint: Hint } {
  return element.type === type && element.typeHint === hint;
}

/**
 * @param element to check
 * @param type to check
 * @param hints List of possible type hints for this ielement
 * @returns true if the element has the proper  (`type`) and type-hint (one listed in `hints`)
 */
export function isIElementWithTypeAndHints<
  Element extends IElement,
  Type extends IElementType,
  Hints extends IElementTypeHint,
>(
  element: Element,
  type: Type,
  hints: IElementTypeHint[],
): element is Element & { type: Type; typeHint: Hints } {
  return element.type === type && hints.some((h) => h === element.typeHint);
}

/**
 * @returns true if the given iElement is a time series.
 * @param iElement element to check
 * @param typeHint if defined return true only if the hint matches
 */
export function isIElementTimeseries(
  iElement: IElement,
  typeHint?: IElementTypeHint,
): iElement is IElementTimeSeries {
  return (
    iElement.type === IElementType.timeSeries &&
    (typeHint === undefined || iElement.typeHint === typeHint)
  );
}

/**
 * @returns true if the given iElement is an image sheet.
 * @param iElement element to check
 */
export function isIElementImgSheet(
  iElement: IElement,
): iElement is IElementImgSheet {
  return iElement.type === IElementType.imgSheet;
}

/**
 * @returns true if the element is a IElementImgSheetTiled
 * @param iElement to check
 */
export function isIElementImgSheetTiled(
  iElement: IElement,
): iElement is IElementImgSheetTiled {
  return iElement.type === IElementType.imgSheetTiled;
}

/**
 * @returns true if the element is a IElementGenericImgSheet
 * @param iElement to check
 */
export function isIElementGenericImgSheet(
  iElement?: IElement,
): iElement is IElementGenericImgSheet {
  return (
    !!iElement &&
    (isIElementImgSheet(iElement) || isIElementImgSheetTiled(iElement))
  );
}

/**
 * @returns true if the given iElement is an ImgSheet that represent a 2d overview of 3d data (OverviewMap)
 * @param iElement element to check
 */
export function isIElementOverviewImage(
  iElement?: IElement,
): iElement is WithHint<
  IElementGenericImgSheet,
  IElementTypeHint.clippingBox | IElementTypeHint.overviewMap
> {
  return (
    !!iElement &&
    isIElementGenericImgSheet(iElement) &&
    (iElement.typeHint === IElementTypeHint.clippingBox ||
      iElement.typeHint === IElementTypeHint.overviewMap)
  );
}

/**
 * @returns true if the given iElement is a panorama image (360 image or image cube).
 * @param iElement element to check
 */
export function isIElementPanoramaImage(
  iElement: IElement,
): iElement is IElementImg360 | IElementImgCube {
  return isIElementImg360(iElement) || isIElementImgCube(iElement);
}

/**
 * @returns true if the given iElement is an IElementImg360.
 * @param iElement element to check
 */
export function isIElementImg360(
  iElement: IElement,
): iElement is IElementImg360 {
  return iElement.type === IElementType.img360;
}

/**
 * @param element to check
 * @returns true if the passed element is an intensity panorama image
 */
export function isIElementImg360GrayScale(
  element: IElement,
): element is WithHint<IElementImg360, IElementTypeHint.grayScale> {
  return (
    isIElementImg360(element) && element.typeHint === IElementTypeHint.grayScale
  );
}

/**
 * @param iElement element to check
 * @returns true iff the argument ielement is a depth map.
 */
export function isIElementDepthMap(
  iElement: IElement,
): iElement is IElementDepthMap {
  return iElement.type === IElementType.depthMap;
}

/**
 * @returns true if the given iElement is an IElementImgCube.
 * @param iElement element to check
 */
export function isIElementImgCube(
  iElement: IElement,
): iElement is IElementImgCube {
  return iElement.type === IElementType.imgCube;
}

/**
 * @returns true if the given iElement is a group.
 * @param iElement element to check
 */
export function isIElementGroup(iElement: IElement): iElement is IElementGroup {
  return iElement.type === IElementType.group;
}

/**
 * @returns true if the given iElement is a group of bim models.
 * @param iElement element to check
 */
export function isIElementBimModelGroup(
  iElement: IElement,
): iElement is IElementGroup {
  return (
    isIElementGroup(iElement) && iElement.typeHint === IElementTypeHint.bimModel
  );
}

/**
 * @returns true if the given iElement is a group of bim Import.
 * @param iElement element to check
 */
export function isIElementBimImportGroup(
  iElement: IElement,
): iElement is IElementGroup {
  return (
    isIElementGroup(iElement) &&
    iElement.typeHint === IElementTypeHint.bimImport
  );
}

/**
 * @returns true if the given iElement is a section of bim models.
 * @param iElement element to check
 */
export function isIElementBimModelSection(
  iElement: IElement,
): iElement is IElementSection {
  return isIElementSectionWithTypeHint(iElement, IElementTypeHint.bimModel);
}

/**
 * @returns true if the given iElement is a time series of bim models.
 * @param iElement element to check
 */
export function isIElementBimModelTimeSeries(
  iElement: IElement,
): iElement is IElementTimeSeries {
  return (
    isIElementTimeseries(iElement) &&
    iElement.typeHint === IElementTypeHint.bimModel
  );
}

/**
 * @returns true if the given iElement is project root.
 * @param iElement element to check
 */
export function isIElementProjectRoot(
  iElement: IElement,
): iElement is IElementProjectRoot {
  return iElement.type === IElementType.projectRoot;
}

/**
 * @returns true if the given iElement is a Section.
 * @param iElement element to check
 */
export function isIElementSection(
  iElement: IElement,
): iElement is IElementSection {
  return iElement.type === IElementType.section;
}

/**
 * @returns true if the given iElement is a Section.
 * @param iElement element to check
 * @param typeHint Optionally, the type hint that the section has to have.
 */
export function isIElementSectionWithTypeHint<Hint extends IElementTypeHint>(
  iElement: IElement,
  typeHint: Hint,
): iElement is WithHint<IElementSection, Hint> {
  return (
    iElement.type === IElementType.section && iElement.typeHint === typeHint
  );
}

/**
 * @returns true if the given iElement is a Section with TimeTravel as typeHint.
 * @param iElement element to check
 */
export function isIElementTimeTravelSection(
  iElement: IElement,
): iElement is WithHint<IElementSection, IElementTypeHint.timeTravel> {
  return isIElementSectionWithTypeHint(iElement, IElementTypeHint.timeTravel);
}

/**
 * @returns true if the given iElement is a Section with DataSetPCloudUpload as typeHint.
 * @param iElement element to check
 */
export function isIElementDataSetPCloudUpload(
  iElement: IElement,
): iElement is WithHint<IElementSection, IElementTypeHint.dataSetPCloudUpload> {
  return isIElementSectionWithTypeHint(
    iElement,
    IElementTypeHint.dataSetPCloudUpload,
  );
}

/**
 * @returns true if the given iElement is a model3d.
 * @param iElement element to check
 */
export function isIElementModel3d(
  iElement: IElement,
): iElement is IElementModel3D {
  return iElement.type === IElementType.model3d;
}

/**
 * @returns true if the given iElement is a img2d.
 * @param iElement element to check
 */
export function isIElementImg2d(iElement: IElement): iElement is IElementImg2d {
  return iElement.type === IElementType.img2d;
}

/**
 * @returns true if the given iElement is a generic point cloud.
 * @param iElement element to check
 */
export function isIElementGenericPointCloud(
  iElement: IElement,
): iElement is IElementGenericPointCloud {
  return (
    isIElementPointCloudCpe(iElement) ||
    isIElementPointCloudE57(iElement) ||
    isIElementPointCloudGeoSlam(iElement) ||
    isIElementPointCloudLaz(iElement)
  );
}

/**
 * @returns true if the given iElement is a IElementPointCloudCpe.
 * @param iElement element to check
 */
export function isIElementPointCloudCpe(
  iElement: IElement,
): iElement is IElementPointCloudCpe {
  return iElement.type === IElementType.pointCloudCpe;
}

/**
 * @returns true if the given iElement is a IElementPointCloudE57.
 * @param iElement element to check
 */
export function isIElementPointCloudE57(
  iElement: IElement,
): iElement is IElementPointCloudE57 {
  return iElement.type === IElementType.pointCloudE57;
}

/**
 * @returns true if the given iElement is a IElementPointCloudE57 which is a flash scan.
 * @param iElement element to check
 */
export function isIElementPointCloudE57Flash(
  iElement: IElement,
): iElement is IElementPointCloudE57 {
  return (
    iElement.type === IElementType.pointCloudE57 &&
    iElement.typeHint === IElementTypeHint.flash
  );
}

/**
 * @returns true if the given iElement is a IElementPointCloudGeoSlam.
 * @param iElement element to check
 */
export function isIElementPointCloudGeoSlam(
  iElement: IElement,
): iElement is IElementPointCloudGeoSlam {
  return iElement.type === IElementType.pointCloudGeoSlam;
}

/**
 * @returns true if the given iElement is a IElementPointCloudLaz.
 * @param iElement element to check
 */
export function isIElementPointCloudLaz(
  iElement: IElement,
): iElement is IElementPointCloudLaz {
  return iElement.type === IElementType.pointCloudLaz;
}

/**
 * @returns true if the given iElement is a streamed point cloud
 * @param iElement element to check
 */
export function isIElementGenericPointCloudStream(
  iElement: IElement,
): iElement is IElementGenericPointCloudStream {
  return (
    isIElementPointCloudStream(iElement) ||
    isIElementPointCloudStreamWebShare(iElement)
  );
}

/**
 * @returns true if the given iElement is a streamed point cloud or CAD
 * @param iElement element to check
 */
export function isIElementGenericStream(
  iElement: IElement,
): iElement is IElementGenericStream {
  return (
    isIElementGenericPointCloudStream(iElement) ||
    isIElementModel3dStream(iElement)
  );
}

/**
 * @returns true if the given iElement is a pointCloudStreamWebShare.
 * @param iElement element to check
 */
export function isIElementPointCloudStreamWebShare(
  iElement: IElement,
): iElement is IElementPointCloudStreamWebShare {
  return iElement.type === IElementType.pointCloudStreamWebShare;
}

/**
 * @returns true if the given iElement is a pointCloudStream.
 * @param iElement element to check
 */
export function isIElementPointCloudStream(
  iElement: IElement,
): iElement is IElementPointCloudStream {
  return iElement.type === IElementType.pointCloudStream;
}

/**
 * @returns true if the given iElement is a Model3dStream.
 * @param iElement element to check
 */
export function isIElementModel3dStream(
  iElement: IElement,
): iElement is IElementModel3dStream {
  return iElement.type === IElementType.model3dStream;
}

/**
 * @returns true if the given iElement is a model3d or a stream
 * @param iElement element to check
 */
export function isIElementGenericModel3d(
  iElement: IElement,
): iElement is IElementGenericModel3d {
  return isIElementModel3d(iElement) || isIElementModel3dStream(iElement);
}

/**
 * @returns true if the given iElement is a IElementAiWall.
 * @param iElement element to check
 */
export function isIElementAiWall(
  iElement: IElement,
): iElement is IElementAiWall {
  return iElement.type === IElementType.aiWall;
}

/**
 * @returns true if the given iElement is a IElementAiMaterialDetection.
 * @param iElement element to check
 */
export function isIElementAiMaterialDetection(
  iElement: IElement,
): iElement is IElementAiMaterialDetection {
  return iElement.type === IElementType.aiMaterialDetection;
}

/**
 * @returns true if the given iElement is a IElementRoomLayout.
 * @param iElement element to check
 */
export function isIElementRoomLayout(
  iElement: IElement,
): iElement is IElementRoomLayout {
  return iElement.type === IElementType.roomLayout;
}

/**
 * @returns true if the given iElement is a IElementFloorLayout.
 * @param iElement element to check
 */
export function isIElementFloorLayout(
  iElement: IElement,
): iElement is IElementFloorLayout {
  return iElement.type === IElementType.floorLayout;
}

/**
 * @returns true if the given iElement is a IElementDropDownMarkupFieldTemplate.
 * @param iElement element to check
 */
export function isIElementDropDownMarkupFieldTemplate(
  iElement: IElement,
): iElement is IElementDropDownMarkupFieldTemplate {
  return iElement.type === IElementType.dropDownMarkupFieldTemplate;
}

/**
 * @returns true if the given iElement is a IElementUserDirectoryMarkupFieldTemplate.
 * @param iElement element to check
 */
export function isIElementUserDirectoryMarkupFieldTemplate(
  iElement: IElement,
): iElement is IElementUserDirectoryMarkupFieldTemplate {
  return iElement.type === IElementType.userDirectoryMarkupFieldTemplate;
}

/**
 * @returns true if the given iElement is a IElementMarkupTemplate.
 * @param iElement element to check
 */
export function isIElementMarkupTemplate(
  iElement: IElement,
): iElement is IElementMarkupTemplate {
  return iElement.type === IElementType.markupTemplate;
}

/**
 * @returns true if the given iElement is a IElementDateTimeMarkupFieldTemplate.
 * @param iElement element to check
 */
export function isIElementDateTimeMarkupFieldTemplate(
  iElement: IElement,
): iElement is IElementDateTimeMarkupFieldTemplate {
  return iElement.type === IElementType.dateTimeMarkupFieldTemplate;
}

/**
 * @returns true if the given iElement is a IElementModel3D representing a dollhouse.
 * @param iElement element to check
 */
export function isIElementDollhouse(
  iElement: IElement,
): iElement is WithHint<IElementModel3D, IElementTypeHint.dollhouse> {
  return (
    isIElementModel3d(iElement) &&
    (!iElement.typeHint || iElement.typeHint === IElementTypeHint.dollhouse)
  );
}

/**
 * @returns true if the given iElement is a IElementModel3D representing a CAD model.
 * @param iElement element to check
 */
export function isIElementCAD(
  iElement: IElement,
): iElement is WithHint<IElementModel3D, IElementTypeHint.cadModel> {
  return (
    isIElementModel3d(iElement) &&
    iElement.typeHint === IElementTypeHint.cadModel
  );
}

/**
 * @returns true if the given iElement is a IElementModel3D representing a BIM model.
 * @param iElement element to check
 */
export function isIElementBimModel(
  iElement: IElement,
): iElement is WithHint<IElementModel3D, IElementTypeHint.bimModel> {
  return (
    isIElementModel3d(iElement) &&
    iElement.typeHint === IElementTypeHint.bimModel
  );
}

/**
 * @returns true if the given iElement is a time series of a DataSession.
 * @param iElement element to check
 */
export function isIElementTimeseriesDataSession(
  iElement: IElement,
): iElement is IElementTimeSeriesDataSession {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.timeSeries,
    IElementTypeHint.dataSession,
  );
}

/**
 * @returns true if the given iElement is a DataSession.
 * @param iElement element to check
 */
export function isIElementSectionDataSession(
  iElement: IElement,
): iElement is WithHint<IElementSection, IElementTypeHint.dataSession> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.section,
    IElementTypeHint.dataSession,
  );
}

/**
 * @returns true if the given iElement is an VideoWalk dataset
 * @param iElement element to check
 */
export function isIElementDataSetVideoWalk(
  iElement: IElement,
): iElement is WithHint<
  IElementDataSetVideoWalk,
  IElementTypeHint.dataSetVideoWalk
> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.section,
    IElementTypeHint.dataSetVideoWalk,
  );
}

/**
 * @returns true if the given iElement is a VideoRecording or a VideoWalk dataset
 * @param iElement element to check
 */
export function isIElementGenericVideoRecording(
  iElement: IElement,
): iElement is IElementGenericVideoRecording {
  return (
    isIElementVideoRecording(iElement) || isIElementDataSetVideoWalk(iElement)
  );
}

/**
 * @returns true if the given iElement is an Orbis dataset
 * @param iElement element to check
 */
export function isIElementSectionGeoslam(
  iElement: IElement,
): iElement is WithHint<IElementSection, IElementTypeHint.dataSetGeoSlam> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.section,
    IElementTypeHint.dataSetGeoSlam,
  );
}

/**
 * @returns true if the given iElement is a section of an area.
 * @param iElement element to check
 */
export function isIElementAreaSection(
  iElement: IElement,
): iElement is WithHint<IElementSection, IElementTypeHint.area> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.section,
    IElementTypeHint.area,
  );
}

/**
 * @returns true if the given iElement is the root section of the capture tree
 * @param iElement element to check
 */
export function isCaptureTreeRoot(
  iElement: IElement,
): iElement is WithHint<IElementSection, IElementTypeHint.captureTree> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.section,
    IElementTypeHint.captureTree,
  );
}

/**
 * @returns true if the given iElement is a markup.
 * @param iElement element to check
 */
export function isIElementMarkup(
  iElement: IElement,
): iElement is IElementMarkup {
  return iElement.type === IElementType.markup;
}

/**
 * @returns whether the given element is an external markup
 * @param iElement element to check
 */
export function isIElementExternalMarkup(
  iElement: IElement,
): iElement is ExternalMarkupIElement {
  switch (iElement.type) {
    case IElementType.markupBim360:
    case IElementType.markupProcoreRfi:
    case IElementType.markupProcoreObservation:
    case IElementType.markupAccIssue:
    case IElementType.markupAccRfi:
      return true;
  }
  return false;
}

/**
 * @returns true if the given iElement is a markup assignee field.
 * @param iElement element to check
 */
export function isIElementMarkupAssigneeId(
  iElement: IElement,
): iElement is WithHint<
  IElementUserDirectoryMarkupField,
  IElementTypeHint.markupAssigneeId
> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.userDirectoryMarkupField,
    IElementTypeHint.markupAssigneeId,
  );
}

/**
 * @returns true if the given iElement is a markup issue due date.
 * @param iElement element to check
 */
export function isIElementMarkupIssueDueDate(
  iElement: IElement,
): iElement is WithHint<
  IElementDateTimeMarkupField,
  IElementTypeHint.markupIssueDueDate
> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.dateTimeMarkupField,
    IElementTypeHint.markupIssueDueDate,
  );
}

/**
 * @returns true if the given iElement is a markup issue status.
 * @param iElement element to check
 */
export function isIElementMarkupIssueStatus(
  iElement: IElement,
): iElement is WithHint<
  IElementDropDownMarkupField,
  IElementTypeHint.markupIssueStatus
> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.userDirectoryMarkupField,
    IElementTypeHint.markupIssueStatus,
  );
}

/**
 * @returns true if the given iElement is a markup issue image.
 * @param iElement element to check
 */
export function isIElementMarkupIssueImage(
  iElement: IElement,
): iElement is WithHint<IElementImg2d, IElementTypeHint.markupIssueImage> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.img2d,
    IElementTypeHint.markupIssueImage,
  );
}

/**
 * @returns True if the iElement is a IElementMarkup
 * @param iElement The element to check
 */
export function isIElementMarkupCommand(
  iElement: IElement,
): iElement is IElementMarkupCommand {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.markup,
    IElementTypeHint.command,
  );
}

/**
 * @returns True if the iElement is a link to another iElement
 * @param iElement The element to check
 */
export function isIElemLink(iElement: IElement): iElement is IElemLink {
  return iElement.type === IElementType.iElemLink;
}

/**
 * @returns True if the iElement is an annotation containing links to iElements inside the project
 * @param iElement The element to check
 */
export function isIElemLinkCommand(
  iElement: IElement,
): iElement is IElemLinkCommand {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.iElemLink,
    IElementTypeHint.command,
  );
}

/**
 * @returns true if the iElement is an UrlLink
 * @param iElement to check
 */
export function isIElementUrlLink(
  iElement: IElement,
): iElement is IElementUrlLink {
  return iElement.type === IElementType.urlLink;
}

/**
 * @param iElement element to check
 * @returns true if the iElement is an IElementUrlLinkCommand
 */
export function isIElementUrlLinkCommand(
  iElement: IElement,
): iElement is IElementUrlLinkCommand {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.urlLink,
    IElementTypeHint.command,
  );
}

/**
 * @param iElement element to check
 * @returns true if the iElement is an IElementPdfCommand
 */
export function isIElementPdfCommand(
  iElement: IElement,
): iElement is IElementPdfCommand {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.pdfAttachment,
    IElementTypeHint.command,
  );
}

/**
 * @param iElement element to check
 * @returns true if the iElement is an isIElementImageCommand
 */
export function isIElementImageCommand(
  iElement: IElement,
): iElement is IElementImageCommand {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.img2d,
    IElementTypeHint.command,
  );
}

/**
 * @param iElement element to check
 * @returns true if the iElement is an IElementWithFileUri
 */
export function isIElementWithUri(
  iElement: IElement,
): iElement is IElementWithUri {
  return Object.hasOwn(iElement, "uri");
}

/**
 * @param iElement element to check
 * @returns true if the iElement is an IElementWithFileUri
 */
export function isIElementWithFileUri(
  iElement: IElement,
): iElement is IElementWithFileUri {
  return (
    isIElementWithUri(iElement) &&
    Object.hasOwn(iElement, "md5Hash") &&
    Object.hasOwn(iElement, "fileSize") &&
    Object.hasOwn(iElement, "fileName")
  );
}

/**
 * @returns true if the input element is a defined object with a valid pose
 * @param element The element to check
 */
export function isIElementWithPose<Element extends IElement>(
  element: Element | undefined,
): element is Element & { pose: IPose } {
  return !!element && !!element.pose;
}

/**
 * @returns true if the input element is timeseries containing VideoRecordings
 * @param iElement The element to check
 */
export function isIElementVideoRecordingTimeseries(
  iElement: IElement,
): iElement is WithHint<IElementTimeSeries, IElementTypeHint.videoRecordings> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.timeSeries,
    IElementTypeHint.videoRecordings,
  );
}

/**
 * @returns true if the input element is a VideoRecording section
 * @param iElement The element to check
 */
export function isIElementVideoRecording(
  iElement: IElement,
): iElement is WithHint<IElementSection, IElementTypeHint.videoRecordings> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.section,
    IElementTypeHint.videoRecordings,
  );
}

/**
 * @returns true if the input element is a track of a VideoRecording
 * @param iElement The element to check
 */
export function isIElementVideoRecordingTrack(
  iElement: IElement,
): iElement is WithHint<IElementSection, IElementTypeHint.videoRecordingTrack> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.section,
    IElementTypeHint.videoRecordingTrack,
  );
}

/**
 * @returns true if the input element is a Video360
 * @param iElement The element to check
 */
export function isIElementVideo360(
  iElement: IElement,
): iElement is IElementVideo360 {
  return iElement.type === IElementType.video360;
}

/**
 * @returns true if the input element is the section containing OdometryPath elements
 * @param iElement The element to check
 */
export function isIElementOdometryPath(
  iElement: IElement,
): iElement is WithHint<IElementSection, IElementTypeHint.odometryPath> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.section,
    IElementTypeHint.odometryPath,
  );
}

/**
 * @returns true if the input element is a pano element of an OdometryPath
 * @param iElement The element to check
 */
export function isIElementPanoInOdometryPath(
  iElement: IElement,
): iElement is WithHint<
  IElementImg360,
  | IElementTypeHint.odometryPath
  | IElementTypeHint.poiLowRes
  | IElementTypeHint.poiHighRes
  | IElementTypeHint.poiStandard
> {
  return isIElementWithTypeAndHints(iElement, IElementType.img360, [
    IElementTypeHint.odometryPath,
    IElementTypeHint.poiLowRes,
    IElementTypeHint.poiHighRes,
    IElementTypeHint.poiStandard,
  ]);
}

/**
 * @returns true if the element is a valid ancestor of an odometry path (i.e. video recording section
 * or data session)
 * @param iElement the input element
 */
export function isIElementOdometryPathAncestor(
  iElement: IElement,
): iElement is IElementSection {
  return (
    isIElementVideoRecording(iElement) || isIElementSectionDataSession(iElement)
  );
}

/**
 * @returns True if the input element is defined and not null
 * @param element The input element
 */
export function isValid<T>(element: T | null | undefined): element is T {
  return !!element;
}

/**
 * @returns True if a pose is valid by checking that all three of
 * position, rotation and scale are defined
 * @param pose The pose to check
 */
export function isValidPose(pose: IPose | undefined | null): boolean {
  return !!pose && !!pose.pos && !!pose.rot && !!pose.scale;
}

/**
 * @returns True if the IElement is a project annotation
 * @param e The input element
 */
export function isIElementAnnotation(
  e: IElement,
): e is WithHint<IElementImg2d, IElementTypeHint.node> {
  return isIElementWithTypeAndHint(
    e,
    IElementType.img2d,
    IElementTypeHint.node,
  );
}

/**
 * @returns True if the IElement is an "assignee" field of a markup
 * @param e The IElement to check
 */
export function isIElementMarkupAssignee(
  e: IElement,
): e is IElementUserDirectoryMarkupField {
  return e.type === IElementType.userDirectoryMarkupField;
}

/**
 * @returns True if the IElement is a "due time" field of a markup
 * @param e The IElement to check
 */
export function isIElementMarkupDueTime(
  e: IElement,
): e is IElementDateTimeMarkupField {
  return e.type === IElementType.dateTimeMarkupField;
}

/**
 * @returns True if the IElement is a "state" field of a markup
 * @param e The IElement to check
 */
export function isIElementMarkupState(
  e: IElement,
): e is IElementDropDownMarkupField {
  return e.type === IElementType.dropDownMarkupField;
}

/**
 * @returns True if the IElement is the template for the "assignee" field of an advanced markup
 * @param iElement The IElement to check
 */
export function isIElementMarkupAssigneeIdTemplate(
  iElement: IElement,
): iElement is WithHint<
  IElementUserDirectoryMarkupFieldTemplate,
  IElementTypeHint.markupAssigneeId
> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.userDirectoryMarkupFieldTemplate,
    IElementTypeHint.markupAssigneeId,
  );
}

/**
 * @returns True if the IElement is the template for the "status" field of an advanced markup
 * @param iElement The IElement to check
 */
export function isIElementMarkupIssueStatusTemplate(
  iElement: IElement,
): iElement is WithHint<
  IElementDropDownMarkupFieldTemplate,
  IElementTypeHint.markupIssueStatus
> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.dropDownMarkupFieldTemplate,
    IElementTypeHint.markupIssueStatus,
  );
}

/**
 * @returns True if the IElement is the template for the "due date" field of an advanced markup
 * @param iElement The IElement to check
 */
export function isIElementMarkupIssueDueDateTemplate(
  iElement: IElement,
): iElement is WithHint<
  IElementDateTimeMarkupFieldTemplate,
  IElementTypeHint.markupIssueDueDate
> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.dateTimeMarkupFieldTemplate,
    IElementTypeHint.markupIssueDueDate,
  );
}

/**
 * @param iElement to check
 * @returns true if it's a MarkupPolygon
 */
export function isIElementMarkupPolygon(
  iElement: IElement,
): iElement is IElementMarkupPolygon {
  return iElement.type === IElementType.markupPolygon;
}

/**
 * @param iElement to check
 * @returns true if it's a MeasurePolygon
 */
export function isIElementMeasurePolygon(
  iElement: IElement,
): iElement is IElementMeasurePolygon {
  return iElement.type === IElementType.measurePolygon;
}

/**
 *  @param iElement to check
 * @returns True if the iElement is one of
 * - IElementMeasurePolygon
 * - IElementMarkupPolygon
 * - IElementModel3d
 * - IElementImg2d
 */
export function isIElementGenericAnnotation(
  iElement: IElement,
): iElement is IElementGenericAnnotation {
  return (
    isIElementMeasurePolygon(iElement) ||
    isIElementMarkupPolygon(iElement) ||
    isIElementModel3d(iElement) ||
    isIElementImg2d(iElement)
  );
}

/**
 * @param iElement iElement to check
 * @returns true if the element is a space annotation
 */
export function isSpaceAnnotation(
  iElement: IElementGenericAnnotation,
): iElement is WithHint<
  IElementGenericAnnotation,
  | IElementTypeHint.spaceAnnotation
  | IElementTypeHint.spaceMeasurement
  | IElementTypeHint.multiScanMeasurement
> {
  return (
    iElement.typeHint === IElementTypeHint.spaceAnnotation ||
    iElement.typeHint === IElementTypeHint.spaceMeasurement ||
    iElement.typeHint === IElementTypeHint.multiScanMeasurement
  );
}

/**
 * @param iElement iElement to check
 * @returns true if the element is a map annotation
 */
export function isMapAnnotation(
  iElement: IElementGenericAnnotation,
): iElement is WithHint<
  IElementGenericAnnotation,
  IElementTypeHint.mapAnnotation | IElementTypeHint.mapMeasurement
> {
  return (
    iElement.typeHint === IElementTypeHint.mapAnnotation ||
    iElement.typeHint === IElementTypeHint.mapMeasurement
  );
}

/**
 * @param iElement to check
 * @returns if it's a group that contains annotations [Group(Nodes)]
 */
export function isAnnotationGroup(
  iElement: IElement,
): iElement is WithHint<IElementGroup, IElementTypeHint.nodes> {
  return isIElementWithTypeAndHint(
    iElement,
    IElementType.group,
    IElementTypeHint.nodes,
  );
}

/**
 * @param iElement to check
 * @returns if it's an IElementAttachment
 */
export function isIElementAttachment(
  iElement: IElement,
): iElement is IElementAttachment {
  return (
    iElement.type === IElementType.attachment && isIElementWithFileUri(iElement)
  );
}

/**
 * @param element The iElement to check.
 * @returns `true` if the element is any of the supported dataset sections, else `false`.
 */
export function isIElementGenericDataset(
  element: IElement,
): element is IElementGenericDataset {
  return (
    isIElementSection(element) &&
    // Using Object.values here to be able to use .includes without TypeScript complaining
    Object.values<string | null | undefined>(
      GENERIC_DATASET_TYPE_HINTS,
    ).includes(element.typeHint)
  );
}

/**
 * @param iElement to check
 * @returns true if it's a ClippingBox
 */
export function isIElementClippingBox(
  iElement: IElement,
): iElement is IElementClippingBox {
  return iElement.type === IElementType.clippingBox;
}
