/**
 * Represents the ids of supported external providers.
 */
export enum ExternalProviderId {
  autodesk = "autodesk",
  procore = "procore",
}

/** Supported units of measure in the HoloBuilder project settings. */
export type SupportedUnitsOfMeasure = "us" | "metric";

export type ProjectSettings = {
  /**
   *  This type depends on the units of measure defined by the backend.
   *  This is not used for storing the data but only for rendering in the UI.
   */
  unitSystem?: SupportedUnitsOfMeasure;
};

/** Markup settings parsed out of the HoloBuilder "slide container" (=project contents) */
export type SlideContainerMarkupSettings = {
  /** Whether markups should be visible for Project Viewers. Otherwise they should only be shown for Editors (or higher) */
  displayForViewers?: boolean;
};

/**
 * The data that are common to all integrations
 */
type CommonIntegrationData = {
  /** The project name as defined by the integration provider */
  projectName: string;

  /** The project Id as defined by the integration provider */
  projectId: string;
};

/**
 * The data that are specific to the Autodesk integration
 */
export type AutodeskIntegrationData = CommonIntegrationData & {
  /**
   * Id of the hub given by autodesk
   * Autodesk's definition of a hub: A Hub is a container of projects, shared resources, and users with a common context.
   */
  hubId: string;

  /** The id/name of the integration provider */
  providerId: ExternalProviderId.autodesk;
};

/**
 * The data that are specific to the Procore integration
 */
export type ProcoreIntegrationData = CommonIntegrationData & {
  /** Unique identifier of a company given by procore */
  companyId: string;

  /** The id/name of the integration provider */
  providerId: ExternalProviderId.procore;
};

/** The integrations enabled in the project */
export type ProjectIntegrations = {
  autodesk?: AutodeskIntegrationData | null;
  procore?: ProcoreIntegrationData | null;
};

/**
 * @returns true if data is a AutodeskIntegrationData
 * @param data the integration to check
 */
export function isAutodeskIntegrationData(
  data: AutodeskIntegrationData | ProcoreIntegrationData,
): data is AutodeskIntegrationData {
  return data.providerId === ExternalProviderId.autodesk;
}

/**
 * @returns true if data is a ProcoreIntegrationData
 * @param data the integration to check
 */
export function isProcoreIntegrationData(
  data: AutodeskIntegrationData | ProcoreIntegrationData,
): data is ProcoreIntegrationData {
  return data.providerId === ExternalProviderId.procore;
}
