import { assert } from "@faro-lotv/foundation";
import { Intersection, Matrix4, Object3D, Points, Ray, Raycaster, Sphere, Texture, Vector3 } from "three";
import { MapPlaceholdersMaterial, PlaceholderSize, PlaceholdersTexture } from "../Materials/MapPlaceholdersMaterial";
import { pixels2m } from "../Utils/CameraUtils";
import { MapPlaceholdersGeometry, PlaceholderStateFlags } from "./MapPlaceholdersGeometry";

export const UNLABELED_PLACEHOLDERS = 0;

/**
 * A class to optimize the rendering and interaction of a high number of 2d placeholders (> 500) on a map
 *
 * Each placeholder can be in one out of four following states: default, hovered, selected, hidden.
 * For each state, except 'hidden' of course, it is possible to define custom pixel size and texture
 * for the placeholders.
 * Furthermore, each placeholder can be unlabeled, or belong to up to four different labels.
 * E.g.: normal, "PoiHighRes", "Locked", "FlashScan", "PoiLowRes".
 * Each of these labels allows the full customization of the placeholder sizes (default, hovered, selected),
 * and of the textures for each state.
 * Despite the high amount of customization possibilities, all placeholders will be rendered in one single draw call.
 */
export class MapPlaceholders extends Points<MapPlaceholdersGeometry, MapPlaceholdersMaterial> {
	/** Private cache objects needed for the raycasting */
	#raycastPrivates = {
		sphere: new Sphere(),
		invMatrix: new Matrix4(),
		localRay: new Ray(),
		position: new Vector3(),
	};

	/** Current viewport height needed for precise raycasting */
	viewportHeight?: number;

	/**
	 * Construct a MapPlaceholders object on a set of points
	 *
	 * @param positions position of all the placeholders
	 */
	constructor(positions: Vector3[]) {
		super(new MapPlaceholdersGeometry(positions), new MapPlaceholdersMaterial());
	}

	/** @returns the number of placeholders */
	get count(): number {
		return this.geometry.attributes.position.count;
	}

	/**
	 * Gets the map of the given type for any of the placeholder labels.
	 *
	 * @param textureType Type of the map requested
	 * @param labelIndex Index of the label whose map is requested
	 * @returns map of the given type for the given label
	 */
	getMap(textureType: PlaceholdersTexture, labelIndex: number = UNLABELED_PLACEHOLDERS): Texture {
		return this.material.getMap(labelIndex, textureType);
	}

	/**
	 * Gets the map of the given type for any of the placeholder labels.
	 *
	 * @param label Placeholder class label, e.g. "PoiHighRes", "Locked", "FlashScan"
	 * @param textureType Type of the map requested
	 * @returns map of the given type for the given label
	 */
	getLabeledMap(label: string, textureType: PlaceholdersTexture): Texture | undefined {
		const labelIndex = this.getLabelIndex(label);
		return labelIndex >= 0 ? this.getMap(textureType, labelIndex) : undefined;
	}

	/**
	 * Sets the map of given type for any of the placeholder labels.
	 *
	 * @param textureType Type of the map being set
	 * @param texture Texture to be set as map of given type for the given label
	 * @param labelIndex Index of the label whose default map is being set
	 */
	setMap(
		textureType: PlaceholdersTexture,
		texture: Texture | undefined,
		labelIndex: number = UNLABELED_PLACEHOLDERS,
	): void {
		this.material.setMap(labelIndex, textureType, texture);
	}

	/**
	 * Sets the map of given type for any of the placeholder labels.
	 *
	 * @param label label whose default map is being set
	 * @param textureType Type of the map being set
	 * @param texture Texture to be set as map of given type for the given label
	 */
	setLabeledMap(label: string, textureType: PlaceholdersTexture, texture: Texture | undefined): void {
		const labelIndex = this.getLabelIndex(label);
		if (labelIndex >= 0) {
			this.setMap(textureType, texture, labelIndex);
		}
	}

	/**
	 *
	 * @param labelIndex Index of the label whose size is requested
	 * @returns The sizes used for the given placeholder label
	 */
	getSizes(labelIndex: number): PlaceholderSize {
		return this.material.getSizes(labelIndex);
	}

	/**
	 *
	 * @param labelIndex Index of the label whose size is being set
	 * @param sizes Sizes to be set for the given placeholder label, in pixels
	 */
	setSizes(labelIndex: number, sizes: PlaceholderSize): void {
		this.material.setSizes(labelIndex, sizes);
	}

	/**
	 *
	 * @param label label whose size is requested
	 * @returns Placeholder sizes associated with the given label, or undefined if the label is not found
	 */
	getLabelSizes(label: string): PlaceholderSize | undefined {
		const labelIndex = this.getLabelIndex(label);
		return labelIndex >= 0 ? this.getSizes(labelIndex) : undefined;
	}

	/**
	 *
	 * @param label label whose size is being set
	 * @param sizes Placeholder sizes to be set for the given label, in pixels
	 */
	setLabelSizes(label: string, sizes: PlaceholderSize): void {
		const labelIndex = this.getLabelIndex(label);
		if (labelIndex >= 0) {
			this.setSizes(labelIndex, sizes);
		}
	}

	/** @returns the index of the current hovered placeholder */
	get hovered(): number | undefined {
		return this.geometry.hovered;
	}

	/** Change the index of the current hovered placeholder */
	set hovered(index: number | undefined) {
		assert(
			index === undefined || (index >= 0 && index < this.count),
			"index should be undefined or a valid placeholder id",
		);
		this.geometry.hovered = index;
	}

	/** @returns the index of the current selected placeholder */
	get selected(): number | undefined {
		return this.geometry.selected;
	}

	/** Change the index of the current selected placeholder */
	set selected(index: number | undefined) {
		assert(
			index === undefined || (index >= 0 && index < this.count),
			"index should be undefined or a valid placeholder id",
		);
		this.geometry.selected = index;
	}

	/** @returns The list of placeholders currently hidden */
	get hidden(): number[] {
		return this.geometry.hidden;
	}

	/** Set the list of placeholders that should be hidden */
	set hidden(indexes: number[]) {
		this.geometry.hidden = indexes;
	}

	/** @returns all placeholder labels currently in use. */
	getAllLabels(): string[] {
		return this.geometry.getAllLabels();
	}

	/**
	 * Returns the index of a given placeholder label. Zero is reserved for unlabeled placeholders.
	 * Four is the maximum value the index can have.
	 *
	 * @param label Label of a placeholder class, e.g. "PoiHighRes", "Locked", "FlashScan"
	 * @returns the index of the label, -1  if the label is not found.
	 */
	getLabelIndex(label: string): number {
		const ret = this.geometry.getAllLabels().findIndex((v) => v === label);
		return ret >= 0 ? ret + 1 : -1;
	}

	/**
	 *
	 * @param label Label of a placeholder class, e.g. "PoiHighRes", "Locked", "FlashScan"
	 * @returns the indices of placeholders labeled with the given label
	 */
	getLabeledPlaceholders(label: string): number[] {
		return this.geometry.getLabeledPlaceholders(label);
	}

	/**
	 *
	 * @param label Label of a placeholder class, e.g. "PoiHighRes", "Locked", "FlashScan"
	 * @param indices The new indices of placeholders labeled with the given label
	 */
	setLabeledPlacehoders(label: string, indices: number[]): void {
		this.geometry.setLabeledPlaceholders(label, indices);
	}

	/**
	 * Compute the size of a specific placeholder based on its current state
	 *
	 * @param index of the placeholder
	 * @returns the actual size
	 */
	#sizeOf(index: number): number {
		const pointState = this.geometry.activeStateAt(index);
		const labelIndex = this.geometry.readLabelAt(index);
		const sizes = this.getSizes(labelIndex);
		switch (pointState) {
			case PlaceholderStateFlags.SELECTED:
				return sizes.selected;
			case PlaceholderStateFlags.HOVERED:
				return sizes.hovered;
		}
		return sizes.default;
	}

	/** Set a custom color for each placeholder */
	set colors(array: Float32Array | undefined) {
		if (array) {
			this.geometry.colors = array;
			this.material.defines = {
				...this.material.defines,
				USE_COLORS: "",
			};
		} else {
			delete this.material.defines.USE_COLORS;
		}
		this.material.needsUpdate = true;
	}

	/**
	 * Update the position of the placeholders, assuming the input array has
	 * the same number of elements as before
	 *
	 * @param positions The new positions
	 */
	updatePositions(positions: Vector3[]): void {
		const attribute = this.geometry.getAttribute("position");
		if (positions.length !== attribute.count) {
			throw new Error("Cannot update using a different number of positions");
		}
		for (const [index, p] of positions.entries()) {
			attribute.setXYZ(index, p.x, p.y, p.z);
		}
		attribute.needsUpdate = true;
	}

	/**
	 * Custom raycaster implementation for precise raycasting
	 *
	 * ThreeJS default to a fixed threshold to decide if a ray intersect a point but this threshold
	 * do not take into account the point size so the picking is imprecise.
	 *
	 * @param raycaster doing the raycast
	 * @param intersects list of intersections
	 */
	raycast(raycaster: Raycaster, intersects: Array<Intersection<Object3D>>): void {
		const { geometry, matrixWorld } = this;
		const { sphere, invMatrix, localRay, position } = this.#raycastPrivates;
		const { camera } = raycaster;

		// Without a camera and a viewport or with an indexed geometry
		// we need to fallback the default ThreeJS algorithm
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- FIXME
		if (!camera || !this.viewportHeight || geometry.index) {
			super.raycast(raycaster, intersects);
			return;
		}

		// Checking boundingSphere distance to ray
		if (geometry.boundingSphere === null) geometry.computeBoundingSphere();
		if (geometry.boundingSphere) sphere.copy(geometry.boundingSphere);
		sphere.applyMatrix4(matrixWorld);
		sphere.radius +=
			pixels2m(
				this.material.maximumSize,
				camera,
				this.viewportHeight,
				camera.position.distanceTo(sphere.center),
			) * 0.5;
		if (raycaster.ray.intersectsSphere(sphere) === false) return;

		// Compute local ray to reduce matrix computations for each point
		invMatrix.copy(matrixWorld).invert();
		localRay.copy(raycaster.ray);

		for (let index = 0; index < geometry.attributes.position.count; index++) {
			if (geometry.attributes.state.array[index] & PlaceholderStateFlags.HIDDEN) {
				continue;
			}

			position.fromBufferAttribute(geometry.attributes.position, index).applyMatrix4(matrixWorld);

			const distance = position.distanceTo(localRay.origin);
			// Compute the threshold to raycast on each point based to the distance from the ray and the
			// current camera parameters
			const threshold = pixels2m(this.#sizeOf(index), camera, this.viewportHeight, distance) / 2;
			const localThresholdSq = threshold * threshold;

			const rayPointDistanceSq = localRay.distanceSqToPoint(position);

			if (rayPointDistanceSq < localThresholdSq) {
				const intersectPoint = new Vector3();

				localRay.closestPointToPoint(position, intersectPoint);

				const distance = raycaster.ray.origin.distanceTo(intersectPoint);

				if (distance < raycaster.near || distance > raycaster.far) return;

				intersects.push({
					distance,
					distanceToRay: Math.sqrt(rayPointDistanceSq),
					point: intersectPoint,
					index,
					face: null,
					object: this,
				});
			}
		}
	}
}
