import { Divider, Stack, StackProps } from "@mui/material";
import { Fragment, forwardRef } from "react";
import { neutral } from "../colors";

/**
 * @returns a wrapper for ToolGroups with the proper FARO style
 */
export const Toolbar = forwardRef<HTMLDivElement, StackProps>(function Toolbar(
  { children, sx = [] },
  ref,
): JSX.Element {
  return (
    <Stack
      ref={ref}
      direction="column"
      sx={[
        {
          backdropFilter: "blur(4px) brightness(40%)",
          borderRadius: 1.5,
          overflow: "hidden",
          backgroundColor: neutral[999],
        },
        // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {Array.isArray(children)
        ? children.map((group, index) => (
            <Fragment key={index}>
              {group}
              {children.length > 1 && index !== children.length - 1 && (
                <Separator />
              )}
            </Fragment>
          ))
        : children}
    </Stack>
  );
});

/** @returns a separator that can be used to separate ToolGroups in a Toolbar */
function Separator(): JSX.Element {
  return (
    <Stack
      sx={{
        width: "100%",
        my: 0.25,
      }}
      justifyContent="center"
    >
      <Divider
        sx={{
          width: "80%",
          margin: "auto",
          opacity: 0.2,
          backgroundColor: "white",
          borderWidth: "1px",
        }}
      />
    </Stack>
  );
}
