import { Stack } from "@mui/material";
import { FaroButton } from "../../button/faro-button";
import { FaroTooltip } from "../../tooltip/tooltip";

export type AnnotationEditorButtonsProps = {
  /**
   * Text to show in the confirm button
   *
   * @default Edit
   */
  confirmButtonText?: string;

  /** Function called when the cancel button is clicked */
  onCancelButtonClick?(): void;

  /** Function called when the confirm button is clicked */
  onConfirmButtonClick?(): void;

  /** Set to true to show a spinner and disable the buttons */
  isSaving: boolean;

  /** True, if the confirm button should be disabled */
  isConfirmButtonDisabled?: boolean;

  /** Tooltip for the confirm button */
  confirmButtonTooltip?: string;
};

/**
 * @returns The buttons to use to confirm or cancel annotation creation or edition
 */
export function AnnotationEditorButtons({
  confirmButtonText = "Save",
  onCancelButtonClick,
  onConfirmButtonClick,
  isSaving,
  isConfirmButtonDisabled,
  confirmButtonTooltip,
}: AnnotationEditorButtonsProps): JSX.Element {
  return (
    <Stack direction="row" justifyContent="end">
      <FaroButton
        variant="ghost"
        dark
        onClick={onCancelButtonClick}
        disabled={isSaving}
      >
        Cancel
      </FaroButton>
      <FaroTooltip title={confirmButtonTooltip}>
        <FaroButton
          dark
          onClick={onConfirmButtonClick}
          isLoading={isSaving}
          disabled={isConfirmButtonDisabled}
        >
          {confirmButtonText}
        </FaroButton>
      </FaroTooltip>
    </Stack>
  );
}
