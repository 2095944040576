import { Matrix4, Plane, Vector3, Vector4 } from "three";
import { makeUniform } from "../Materials/Uniforms";
import { computeOrthogonalVector } from "../Utils";
import { Colormap } from "./ColormapPoints";

/** Max number of color keys for color scale */
export const MAX_NUMBER_OF_COLOR_KEYS = 10;
/** Max number of polygon points for a colormap region border */
export const MAX_NUMBER_OF_POLYGON_POINTS = 50;
/** Max distance from point to the reference plane for the point to be colorized */
export const COLORMAP_MAX_DISTANCE = 0.1;

/**
 * Parameters define one colormap region in the point cloud.
 */
export type ColormapParameters = {
	/** Reference plane normal direction */
	planeNormal: Vector3;
	/** Reference plane point */
	planePoint: Vector3;
	/** Polygon points defining the region border */
	polygon: Vector3[];
	/** Colormap to use for coloring the points */
	colormap: Colormap;
	/** The deviation value associated with the minimum color range */
	minDeviation: number;
	/** The deviation value associated with the maximum color range */
	maxDeviation: number;
	/** The transformation matrix to apply to the data */
	transform: Matrix4;
};

/**
 * Uniforms required to render colormap within a point cloud.
 */
export class PointCloudColormapUniforms {
	uniforms = {
		uReferencePlane: makeUniform(new Vector4()),
		uBoundingSphereRadius: makeUniform(0),
		uPolygonOrigin: makeUniform(new Vector3()),
		uPolygonDirU: makeUniform(new Vector3()),
		uPolygonDirV: makeUniform(new Vector3()),
		uColorKeySize: makeUniform(0),
		uPolygonSize: makeUniform(0),
		uColorKey: makeUniform(new Float32Array()),
		uPolygon: makeUniform(new Float32Array()),
		uMinDeviation: makeUniform(0),
		uMaxDeviation: makeUniform(0),
	};

	/**
	 * Set the colormap uniforms values.
	 *
	 * @param colormapParams The colormap parameters to set
	 */
	setColormap(colormapParams: ColormapParameters): void {
		const { colormap, polygon } = colormapParams;
		const colorsSize = Math.min(colormap.length, MAX_NUMBER_OF_COLOR_KEYS);
		const polygonSize = Math.min(polygon.length, MAX_NUMBER_OF_POLYGON_POINTS);

		const point = colormapParams.planePoint.clone().applyMatrix4(colormapParams.transform);
		const normal = colormapParams.planeNormal.clone().transformDirection(colormapParams.transform);
		const referencePlane = new Plane().setFromNormalAndCoplanarPoint(normal, point);

		const planeU = computeOrthogonalVector(normal).normalize();
		const planeV = normal.clone().cross(planeU).normalize();

		let boundingSphereRadius = 0;
		for (const p of polygon) {
			const d = p.distanceTo(colormapParams.planePoint);
			boundingSphereRadius = Math.max(boundingSphereRadius, d);
		}
		const colorKeyData = new Float32Array(colorsSize * 4);
		for (let i = 0; i < colorsSize; i++) {
			const { value, color } = colormap[i];
			colorKeyData[i * 4 + 0] = color.r;
			colorKeyData[i * 4 + 1] = color.g;
			colorKeyData[i * 4 + 2] = color.b;
			colorKeyData[i * 4 + 3] = value;
		}
		const tmp = new Vector3();
		const polygonData = new Float32Array(polygonSize * 2);
		for (let i = 0; i < polygonSize; i += 1) {
			tmp.copy(polygon[i]);
			tmp.applyMatrix4(colormapParams.transform);
			tmp.sub(point);
			polygonData[i * 2] = tmp.dot(planeU);
			polygonData[i * 2 + 1] = tmp.dot(planeV);
		}

		this.uniforms.uReferencePlane.value.set(
			referencePlane.normal.x,
			referencePlane.normal.y,
			referencePlane.normal.z,
			referencePlane.constant,
		);
		this.uniforms.uPolygonOrigin.value.copy(point);
		this.uniforms.uBoundingSphereRadius.value = boundingSphereRadius;
		this.uniforms.uPolygonDirU.value.copy(planeU);
		this.uniforms.uPolygonDirV.value.copy(planeV);
		this.uniforms.uColorKeySize.value = colorsSize;
		this.uniforms.uColorKey.value = colorKeyData;
		this.uniforms.uPolygonSize.value = polygonSize;
		this.uniforms.uPolygon.value = polygonData;
		this.uniforms.uMinDeviation.value = colormapParams.minDeviation;
		this.uniforms.uMaxDeviation.value = colormapParams.maxDeviation;
	}
}
