import { ArrowBack } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { ReactNode, useRef, useState } from "react";
import { UserDisplayInfo } from "../../types";
import { FaroButton } from "../button/faro-button";
import { neutral } from "../colors";
import { FaroIconButton } from "../icon-button/faro-icon-button";
import { FaroText } from "../text/faro-text/faro-text";
import { FaroTooltip } from "../tooltip/tooltip";
import { UserAvatar } from "../user-avatar/user-avatar";
import {
  MenuAccountSecurityOption,
  MenuSignOutOption,
  UserMenu,
} from "../user-menu/user-menu";
import { UserMenuProps } from "../user-menu/user-menu-types";

export type ToolHeaderBarProps = {
  /** The name of the tool to show the header bar for. */
  toolName: string;

  /** Info about the currently logged in user. */
  userDisplayInfo?: UserDisplayInfo;

  /** A callback to execute when the user wants to log in. */
  onLogin(): void;

  /** A callback to execute when the user wants to log out. */
  onLogout(): void;

  /** A callback to execute when the user wants to access the account and security infos. */
  onAccountAndSecurity(): void;

  /** A URL to redirect to when the user presses on the exit button. */
  exitHref?: string;

  /** A callback to execute when the user presses on the exit button. */
  onExit?(): void;

  /** Whether page navigation should be disabled */
  disableNavigation?: boolean;

  /** Current version of the app. It will be displayed in the about dialog in the user menu */
  appVersion?: string;

  /** Links to display in the user menu. */
  menuLinks: UserMenuProps["links"];

  /** Additional items that are shown on the right side of the toolbar, next to the user-menu. */
  contentRight?: ReactNode;

  /** Extra content for the user menu */
  userMenuContent?: ReactNode;
};

/** @returns A header bar for an application tool. */
export function ToolHeaderBar({
  toolName,
  userDisplayInfo,
  onLogin,
  onLogout,
  onAccountAndSecurity,
  exitHref,
  onExit,
  appVersion,
  menuLinks,
  contentRight,
  userMenuContent,
  disableNavigation,
}: ToolHeaderBarProps): JSX.Element {
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const profileButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        borderBottom: `1px solid ${neutral[200]}`,
        backgroundColor: neutral[0],
      }}
    >
      <Stack
        alignSelf="stretch"
        sx={{
          borderRight: `1px solid ${neutral[200]}`,
        }}
      >
        <FaroButton
          variant="ghost"
          size="l"
          sx={{ height: "100%", borderRadius: 0, px: 3 }}
          href={exitHref}
          onClick={onExit}
          disabled={disableNavigation}
        >
          <FaroText
            variant="heading16"
            display="inline-flex"
            alignItems="center"
            gap={1}
          >
            <ArrowBack />
            Exit
          </FaroText>
        </FaroButton>
      </Stack>

      <FaroText variant="heading16">{toolName}</FaroText>

      <Stack direction="row" alignItems="center" gap={1}>
        {contentRight}

        <FaroTooltip title={userDisplayInfo?.email ?? "Login"}>
          <FaroIconButton
            onClick={() =>
              userDisplayInfo?.email ? setIsProfileMenuOpen(true) : onLogin?.()
            }
            ref={profileButtonRef}
            sx={{
              // Let the user avatar determine the size
              [">:first-of-type"]: {
                width: "auto",
                height: "auto",
              },
            }}
          >
            <UserAvatar
              userDisplayInfo={userDisplayInfo}
              shouldShowGradientBorder
              size="s"
            />
          </FaroIconButton>
        </FaroTooltip>
      </Stack>

      {profileButtonRef.current && userDisplayInfo && (
        <UserMenu
          anchorElement={profileButtonRef.current}
          open={isProfileMenuOpen}
          closeMenu={() => setIsProfileMenuOpen(false)}
          userDisplayInfo={userDisplayInfo}
          links={menuLinks}
          appVersion={appVersion}
        >
          <MenuAccountSecurityOption onClick={onAccountAndSecurity} />
          {userMenuContent}
          <MenuSignOutOption onClick={onLogout} />
        </UserMenu>
      )}
    </Stack>
  );
}
