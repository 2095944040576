import {
	Camera,
	DepthTexture,
	FloatType,
	GLSL3,
	NearestFilter,
	RawShaderMaterial,
	RedFormat,
	Texture,
	Vector2,
	Vector3,
	WebGLRenderTarget,
	WebGLRenderer,
} from "three";
import { FullScreenQuad } from "three/examples/jsm/postprocessing/Pass.js";
import { makeUniform } from "../Materials/Uniforms";
import frag from "../Shaders/Depth2Color.frag";
import vert from "../Shaders/TexturedQuadNoVersion.vert";

/**
 * A material to copy depth information as color
 */
export class Depth2ColorMaterial extends RawShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;
	override uniforms = {
		uDepthTexture: makeUniform<Texture | null>(null),
	};
	constructor() {
		super({ glslVersion: GLSL3 });
	}
}

/** A class with capabilities of reading a depth buffer to RAM */
export class DepthBufferReader {
	#depthBuffer: Float32Array;
	#auxFbo: WebGLRenderTarget;
	#size = new Vector2(4, 4);
	#fsQuad: FullScreenQuad;
	#depth2colorMaterial = new Depth2ColorMaterial();

	constructor() {
		this.#depthBuffer = new Float32Array(this.#size.x * this.#size.y);
		this.#auxFbo = this.#createAuxFbo();
		this.#fsQuad = new FullScreenQuad(this.#depth2colorMaterial);
	}

	/** @returns a new aux FBO to render the depth onto as color. */
	#createAuxFbo(): WebGLRenderTarget {
		const fbo = new WebGLRenderTarget(this.#size.x, this.#size.y);
		fbo.texture.format = RedFormat;
		fbo.texture.type = FloatType;
		fbo.texture.minFilter = NearestFilter;
		fbo.texture.magFilter = NearestFilter;
		fbo.texture.generateMipmaps = false;
		fbo.texture.name = "Depth2ColorTexture";
		fbo.stencilBuffer = false;
		fbo.depthBuffer = false;
		return fbo;
	}

	/**
	 * Resize internal FBO and memory buffer to the new size.
	 *
	 * @param width Target size width
	 * @param height Target size height
	 */
	resize(width: number, height: number): void {
		if (width === this.#size.x && height === this.#size.y) return;
		this.#size.set(width, height);
		this.#auxFbo.dispose();
		this.#auxFbo = this.#createAuxFbo();
		this.#depthBuffer = new Float32Array(this.#size.x * this.#size.y);
	}

	/**
	 * Copies a given depth buffer in GPU to RAM memory.
	 *
	 * @param depthTexture The texture with the depth buffer
	 * @param width pixels width of the given depth texture
	 * @param height pixels height of the given depth texture
	 * @param renderer webgl renderer
	 */
	read(depthTexture: DepthTexture, width: number, height: number, renderer: WebGLRenderer): void {
		this.resize(width, height);

		// set depth texture to the depth blit material
		const oldRenderTarget = renderer.getRenderTarget();
		renderer.setRenderTarget(this.#auxFbo);
		const oldAutoClear = renderer.autoClear;
		renderer.autoClear = true;

		this.#depth2colorMaterial.uniforms.uDepthTexture.value = depthTexture;
		this.#fsQuad.render(renderer);

		// copying depth rendered as colors from the FBO in GPU to a buffer in CPU memory.
		renderer.readRenderTargetPixels(this.#auxFbo, 0, 0, this.#auxFbo.width, this.#auxFbo.height, this.#depthBuffer);

		// Restoring rendering parameters
		renderer.setRenderTarget(oldRenderTarget);
		renderer.autoClear = oldAutoClear;
	}

	/**
	 * Returns the depth value at pixel (x, y)
	 *
	 * @param x Pixel x coordinate
	 * @param y Pixel y coordinate
	 * @returns Depth buffer value at pixel x, y
	 */
	depthAt(x: number, y: number): number {
		if (x < 0 || x >= this.#size.x) return 1;
		if (y < 0 || y >= this.#size.y) return 1;
		return this.#depthBuffer[(this.#size.y - 1 - y) * this.#size.x + x];
	}

	/**
	 * Returns a point in world coordinates given a pixel and a camera
	 *
	 * @param x Pixel x coordinate
	 * @param y Pixel y coordinate
	 * @param camera The camera rendering the scene
	 * @param result Result vector to avoid allocations
	 * @returns World coordinates at pixel (x, y);
	 */
	worldCoordinatesAt(x: number, y: number, camera: Camera, result = new Vector3()): Vector3 {
		const depth = this.depthAt(x, y);
		result.set((2 * x) / this.#size.x - 1, 1 - (2 * y) / this.#size.y, depth * 2 - 1);
		result.unproject(camera);
		return result;
	}

	/** Disposes GPU resources used by this class. */
	dispose(): void {
		this.#auxFbo.dispose();
		this.#fsQuad.dispose();
		this.#depth2colorMaterial.dispose();
	}

	/** @returns the buffer of depth values */
	depthBuffer(): Float32Array {
		return this.#depthBuffer;
	}
}
